<template>
  <div id="graduates" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="graduates" :paginated="isPaginated" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          graduates.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.student.name
        }}</b-table-column>

        <b-table-column label="School Class" v-slot="props">{{
          props.row.schoolClass.name
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'graduates',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Graduates Info',
      graduates: [],
      id: null,
      context: 'remove',
      isPaginated: true,
    }
  },
  watch: {
    graduates(data) {
      if (data.length < 20) this.isPaginated = false
    },
  },
  methods: {
    initializeInfo(graduate) {
      this.$router.push(
        `/school/${this.schoolId}/graduates/${this.$route.params.term_id}/graduate_info/${graduate.id}`
      )
    },
    initializeUpdate(graduate) {
      this.$router.push(
        `/school/${this.schoolId}/student/${graduate.student.id}/graduate`
      )
    },
    initializeRemove(graduate) {
      this.$buefy.notification.open({
        duration: 5000,
        message: 'Not Allowed.',
        position: 'is-top',
        type: 'is-danger',
        hasIcon: true,
      })
      return
      this.openModal()
      this.id = parseInt(graduate.id)
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteGraduateQuery($id: Int!) {
              deleteGraduate(input: { id: $id }) {
                graduate {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteGraduate.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.graduates.refetch()
          })
        })
        .catch((error) => {
          // console.error(error);
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Graduates',
        route: `/school/${this.schoolId}/graduates`,
      },
    ])
    this.$apollo.addSmartQuery('graduates', {
      query: gql`
        query GraduatesQuery($termId: ID!) {
          graduates(termId: $termId) {
            id
            term {
              id
              sessionYear
            }
            student {
              id
              name
            }
            schoolClass {
              id
              name
            }
          }
        }
      `,
      variables: {
        termId: parseInt(this.$route.params.term_id),
      },
    })
    this.$apollo.queries.graduates.refetch()
  },
}
</script>
